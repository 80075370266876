import React from 'react';

export const LogoWithText = () => (
  <svg
    width="169"
    height="38"
    viewBox="0 0 169 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.2524 12.3942V15.6636C60.0079 14.4021 64.577 15.0342 64.9556 17.0536C65.3383 20.0868 56.7045 23.7452 55.0565 24.3799C54.9278 24.5059 54.8031 24.3812 54.6771 24.2552L54.6751 24.2532C54.6578 24.1147 54.7044 23.9759 54.8018 23.8759C59.37 21.8547 64.0689 18.9482 63.4342 17.4336C62.5448 15.663 53.7857 16.5524 34.7422 21.2268C12.0184 26.7879 1.10058 26.0279 0.212547 23.2467C-0.680929 20.7215 6.43283 17.8164 8.08767 17.1858H8.21434V17.3111C8.02224 17.4134 7.77331 17.5398 7.4848 17.6863C5.29612 18.7979 0.82945 21.0664 1.61405 22.7428C1.99678 23.8841 5.67556 24.1361 12.0239 23.3774V16.1779C11.9921 15.1596 12.5172 14.2047 13.3941 13.6861C14.2711 13.1675 15.3609 13.1675 16.2378 13.6861C17.1148 14.2047 17.6399 15.1596 17.6081 16.1779V22.6188C18.913 22.4331 20.2245 22.1794 21.6371 21.9061L21.6371 21.9061C22.1412 21.8085 22.6582 21.7085 23.1923 21.6082V7.84512C23.2394 6.33702 24.4756 5.13886 25.9844 5.13886C27.4933 5.13886 28.7295 6.33702 28.7766 7.84512V20.3511L28.8346 20.3378L28.8346 20.3378C30.4715 19.9636 32.1083 19.5894 33.8582 19.2152C34.0127 19.127 34.1885 19.0831 34.3662 19.0885V2.91465C34.384 1.38173 35.6267 0.14571 37.1597 0.136158H37.1733C38.7416 0.102698 40.0402 1.34645 40.0744 2.91465V17.6992C41.9799 17.3192 43.8839 16.9392 45.532 16.5606V12.3942C45.532 10.8146 46.8125 9.53401 48.3922 9.53401C49.9718 9.53401 51.2524 10.8146 51.2524 12.3942ZM12.0511 28.9712V31.0878C12.0845 32.5905 13.3122 33.7913 14.8153 33.7913C16.3184 33.7913 17.5461 32.5905 17.5795 31.0878V28.3461C15.7497 28.6565 13.9041 28.8652 12.0511 28.9712ZM23.21 35.1084V27.4076C25.0738 27.1386 26.9198 26.7589 28.7384 26.2704V35.216C28.7297 36.2108 28.1872 37.1242 27.3178 37.6079C26.4485 38.0915 25.3863 38.0708 24.5365 37.5537C23.6866 37.0366 23.1801 36.1028 23.21 35.1084Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.5277 22.5268V22.5235L45.5236 22.5275C45.525 22.5273 45.5263 22.5271 45.5277 22.5268ZM45.5277 22.5268V25.7923C45.548 26.5452 45.8667 27.2591 46.4136 27.7769C46.9606 28.2947 47.6909 28.5739 48.4437 28.5531H48.4601C49.9896 28.5495 51.2345 27.3216 51.259 25.7923V21.3984C49.6038 21.7732 47.6934 22.1493 45.5277 22.5268ZM34.3701 25.0404H34.7501V25.0377C36.2135 24.6513 37.6003 24.3406 38.9132 24.0464C39.3172 23.9559 39.7141 23.867 40.1042 23.7779V30.4517C40.0372 31.9865 38.7734 33.1964 37.2371 33.1964C35.7008 33.1964 34.437 31.9865 34.3701 30.4517V25.0404Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.1786 16.2527C89.9302 15.9493 89.6138 15.7086 89.2552 15.5499C88.855 15.3795 88.4233 15.2959 87.9885 15.3048C87.0758 15.295 86.2032 15.6792 85.5941 16.359C85.2861 16.7053 85.0475 17.1076 84.8913 17.5439C84.5535 18.5095 84.5535 19.5611 84.8913 20.5267C85.0481 20.9585 85.2836 21.3575 85.5859 21.7035C85.8743 22.0315 86.2277 22.2959 86.6238 22.4798C87.0261 22.6643 87.4642 22.7573 87.9068 22.7522C88.39 22.7618 88.8678 22.6493 89.296 22.4254C89.699 22.2076 90.0416 21.8931 90.293 21.5101L92.4177 23.0955C91.9489 23.7644 91.304 24.2905 90.5545 24.6155C89.8249 24.9373 89.0365 25.1042 88.2391 25.1058C87.3868 25.1148 86.5399 24.9708 85.7385 24.6808C85.0029 24.4145 84.3298 24 83.7608 23.4632C83.1966 22.9223 82.7537 22.2677 82.4615 21.5428C81.839 19.9206 81.839 18.1255 82.4615 16.5033C82.7537 15.7784 83.1966 15.1239 83.7608 14.5829C84.3298 14.0461 85.0029 13.6317 85.7385 13.3653C86.5399 13.0753 87.3868 12.9314 88.2391 12.9403C88.5815 12.9417 88.9232 12.9718 89.2606 13.0302C89.6178 13.091 89.9682 13.1868 90.3066 13.3163C90.656 13.4503 90.988 13.6259 91.2954 13.8393C91.617 14.0624 91.9035 14.3324 92.1453 14.6401L90.1786 16.2527Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.6946 16.5142C95.3749 17.3143 95.2166 18.1697 95.2287 19.0312C95.2159 19.8945 95.3742 20.7518 95.6946 21.5536C95.9868 22.2785 96.4297 22.9331 96.9939 23.474C97.5629 24.0109 98.236 24.4253 98.9715 24.6917C100.59 25.2583 102.354 25.2583 103.973 24.6917C104.708 24.4253 105.381 24.0109 105.95 23.474C106.515 22.9331 106.958 22.2785 107.25 21.5536C107.872 19.9315 107.872 18.1363 107.25 16.5142C106.958 15.7893 106.515 15.1347 105.95 14.5938C105.381 14.0569 104.708 13.6425 103.973 13.3761C102.354 12.8095 100.59 12.8095 98.9715 13.3761C98.236 13.6425 97.5629 14.0569 96.9939 14.5938C96.4297 15.1347 95.9868 15.7893 95.6946 16.5142ZM98.1298 20.5294C97.9559 20.0493 97.8701 19.5418 97.8765 19.0312C97.8722 18.5252 97.9579 18.0225 98.1298 17.5466C98.2882 17.1058 98.5358 16.7024 98.8571 16.3616C99.1774 16.0264 99.5643 15.7619 99.993 15.5853C100.944 15.2149 102 15.2149 102.951 15.5853C103.38 15.7619 103.767 16.0264 104.087 16.3616C104.409 16.7024 104.656 17.1058 104.815 17.5466C105.152 18.5122 105.152 19.5638 104.815 20.5294C104.655 20.967 104.407 21.3674 104.087 21.7062C103.767 22.0414 103.38 22.3059 102.951 22.4825C102 22.8529 100.944 22.8529 99.993 22.4825C99.5643 22.3059 99.1774 22.0414 98.8571 21.7062C98.5372 21.3674 98.2897 20.967 98.1298 20.5294Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.968 23.9998C121.226 24.3886 120.434 24.6745 119.614 24.8497C118.792 25.0245 117.954 25.1122 117.114 25.1112C116.262 25.1202 115.415 24.9762 114.613 24.6862C113.879 24.4195 113.206 24.0051 112.638 23.4686C112.074 22.9277 111.631 22.2731 111.339 21.5482C110.716 19.926 110.716 18.1309 111.339 16.5087C111.631 15.7838 112.074 15.1292 112.638 14.5883C113.207 14.0515 113.88 13.6371 114.616 13.3707C115.417 13.0807 116.264 12.9368 117.117 12.9457C117.981 12.9375 118.842 13.0503 119.674 13.2808C120.439 13.4981 121.142 13.8945 121.723 14.4371L119.925 16.3984C119.592 16.0495 119.192 15.7716 118.748 15.5812C118.227 15.3826 117.671 15.29 117.114 15.3088C116.608 15.2998 116.105 15.3924 115.635 15.5812C115.206 15.7578 114.819 16.0223 114.499 16.3576C114.177 16.6983 113.93 17.1017 113.771 17.5425C113.434 18.5081 113.434 19.5597 113.771 20.5253C113.931 20.9629 114.179 21.3634 114.499 21.7021C114.819 22.0374 115.206 22.3018 115.635 22.4784C116.105 22.6672 116.608 22.7598 117.114 22.7508C117.6 22.7603 118.085 22.6969 118.552 22.5629C118.885 22.4659 119.208 22.3373 119.516 22.1788V20.2992H117.441V17.9525H121.968V23.9998Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.25 13.2454H129.715L134.635 21.2812H134.667V13.2454H137.217V24.8224H133.885L128.832 16.5796H128.8V24.817H126.25V13.2454Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.581 13.2454H144.13V24.8224H141.581V13.2454Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.734 15.5008H147.432V13.2454H156.585V15.5008H153.283V24.817H150.734V15.5008Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.017 13.2454H167.878V15.5989H162.566V17.7563H167.584V20.1099H162.566V22.4634H168.172V24.817H160.017V13.2454Z"
      fill="white"
    />
  </svg>
);
export default LogoWithText;
